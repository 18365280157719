.back-to-top {
    position: fixed;    
    bottom: 1.1em;
    right: 0.5em;
    font-size: 2.5rem;
    color: var(--black);
    transition: 0.5s;
    cursor: pointer;
}
.back-to-top:hover {
    transition: 0.5s;
    color: var(--cyan);
}

/*Devices*/
@media (max-width: 480px) {
    .back-to-top {
        bottom: 0.5em;
        right: 0.5em;
    }
}