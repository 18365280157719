.grid-radio {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 2em;
    grid-row-gap: 2em;
}
.card-radio {
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
}
.card-radio img {
    border-radius: 1.4em;
    width: 100%;
    height: 100%;
}
.card-radio:hover {
    box-shadow: none;
    transition: 0.5s;
    transform: translate(10px, 10px);
    cursor: pointer;
}

@media (max-width: 480px) {
    .grid-radio {
        grid-template-columns: repeat(1,1fr);
    }
}