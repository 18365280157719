/* Start: Topics */
.blog-topics {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    padding: 5em;
    -webkit-user-select: none;
    user-select: none;
}
.card-postx:hover i {
    color: var(--purple);
}

.label-topic {
    padding: 1em;
    font-size: 2em;
}

.text-out {
    text-align: center;
    background-color: white;    
}


/* Filter Blog */
.filter-blog {
    text-align: center;
}
.labels {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-column-gap: 0.2em;
    grid-row-gap: 0.2em;
}
.labels button {
    /* margin: 1em; */
    cursor: pointer;
    font: 0.8em "Montserrat";
    background-color: var(--pink);
    color: var(--white);
    border: none;
}
.labels button:hover {
    background-color: var(--lightpink);
    color: var(--darkgray);
}

/* Feed Post */
.feed-blog {
    padding-top: 5em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}

/* Card Post */
.card-postx {
    background-color: var(--white);
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height: 10em;
    width: 100%;
}
.card-postx:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

.card-post {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.post-cover {
    width: 100%;
}
.text-post {
    position: absolute;
    text-align: center;
    /* padding: 1em; */
    font-weight: 600;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.text-topic {
    position: absolute;
    text-align: center;
    /* padding: 1em; */
    font-weight: 600;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* New Post Card */
.post-date {
    position:sticky;
    text-align: left;
    font-weight: 600;
    margin-bottom: -2em;
    z-index: 1;
}
.post-date a {
    background-color: var(--darkgray);
    color: var(--white);
    padding: 0.5em 1em;
}

.date {
    text-transform: capitalize;
}

/* Label Title */
.label-title {
    text-align: center;
}

/* Before Foot */
.before-foot {
    height: 2em;
    background: var(--pink);
}

/* Start: Search */
.search {
    margin-top: 3em;
    display: flex;
    justify-content: center;
}
.search input {
    text-align: center;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 2rem;
    padding-inline: 1em;
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    color: var(--purple);
}
input:focus {
    outline: none;
}
/* End: Search */

/* Start: Pop Up */
.popup-container {
    width: 100vh;
    background: black;
}
.me-main {
    max-width: 700px;
    max-height: 600px;
    margin: auto;
    overflow: overlay;
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%,-50%);
    background: var(--purple);
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    z-index: 1;
}
.close-popup {
    font-size: 2em;
    margin-top: 0.3em;
    margin-right: 0.5em;
    text-align: end;
    margin-bottom: -1em;
    z-index: 1;
}
.close-popup i {
    cursor: pointer;
}
/* End: Pop Up */



/* Start: Image Gallery */
.image-card {
    width: 100%;
    height: 15em;
    object-fit: cover;
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    box-shadow: 10px 10px 1px var(--darkgray);
    cursor: pointer;
}
.image-card:hover {
    box-shadow: none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

#lightbox-img {
    height: 70vh;
    max-width: 80vw;
    object-fit: cover;
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    box-shadow: 10px 10px 1px var(--darkgray);
}
#lightbox-img:hover {
    box-shadow: none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cont);
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/*Completely optional styling that has nothing to do with the lightbox*/
.prev-next {
    color: var(--darkgray);
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    background-color: var(--realpink);
    font-size: 1.2em;
}

.pic-gallery:hover, button:hover {
    cursor: pointer;
}


/*Phone Devices*/
@media (max-width: 480px) {
    .blog-topics {
        grid-template-columns: repeat(1,1fr);
        padding-inline: 1.5em;
        padding-top: 1em;
    }
    .labels {
        grid-template-columns: repeat(4,1fr);
    }
    .feed-blog {
        grid-template-columns: repeat(1, 1fr);
        padding-top: 1em;
    }
    .text-post {
        font-size: 1.2em;
    }
    /* Start: Image Gallery */
    #lightbox-img {
        width: 70%;
        height: auto;
    }
    /* Start: Image Gallery */
}
@media (min-width: 481px) and (max-width: 768px) {
    .blog-topics {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 1em;
    }
    /* Start: Image Gallery */
    #lightbox-img {
        width: 100%;
        height: auto;
    }
    /* Start: Image Gallery */
}
@media (min-width: 769px) and (max-width: 1024px) {
    .blog-topics {
        grid-template-columns: repeat(3, 1fr);
        padding-top: 1em;
    }
    /* Start: Image Gallery */
    #lightbox-img {
        width: 100%;
        height: auto;
    }
    /* Start: Image Gallery */
}