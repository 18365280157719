.iconx {
    font-size: 2em;
    text-align: center;
    margin-top: 0.25em;
    padding: 0;
}
.icontype {
    text-align: center;
    display: flex;
    margin: 0 1em;
    justify-content: center;
    background-color: var(--white);
    border: 2px solid var(--darkgray);
    border-radius: 100px;
    width: 3em;
    height: 3em;
    transition: transform 0.5s;
}
.icontype:hover {
    transition: 0.5s;
    background: var(--darkgray);
    color: var(--darkpink);
}

.sub-cont {
    padding: 6em 0;
    -webkit-user-select: none;
    user-select: none;
}

.computer {
    background: url('../pix/tbp-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 1.5em;
    height: 13em;
}

.welcome {
    display: grid;
    /* grid-template-columns: 15em auto;
    grid-column-gap: 1em; */
    -webkit-user-select: none;
    user-select: none;
}
.welcome-logo {
    text-align: center;
    height: 5em;
}
.welcome-logo img {
    height: 100%;
}
.welcome-text {
    background-color: var(--white);
    text-align: center;
    border-radius: 1.5em;
    margin: 1em 5em;
    padding-left: 2em;
    padding-right: 2em;
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
}
.welcome-text h1 {
    font-size: 2.5em;
    word-spacing: 0.3em;
}
.lpage {
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}

.link-page {
    font-family: 'Outfit', sans-serif;
    font-size: 1em;
    width: 10em;
    text-align: center;
    transition: 0.5s;
    padding: 0.5em;
}
.link-page:hover {
    transition: 0.5s;
    font-size: 1em;
    border-radius: 1em;
    border: 2px solid var(--darkgray);
    color: var(--cont);
    background: var(--purple);
}

.second-welcome {
    padding: 2em 0 2em 0;
}

.extras {
    display: flex;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
}

.extras a {
    margin: 1em auto;
}


.last-posts {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 3em;
    padding: 1em 5em 5em 5em;
    -webkit-user-select: none;
    user-select: none;
}

.card-post {
    background-color: var(--white);
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    height: 10em;
    width: 100%;
}
.card-post:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

.date {
    position:sticky;
    text-align: left;
    font-weight: 600;
    margin-top: -0.7em;
    padding-left: 1em;
}

.date a {
    background-color: var(--pink);
    color: var(--darkgray);
    padding: 0.2em 1em;
    border: 2px solid var(--darkgray);
    border-radius: 1.5em;
}

/* Start: Grid Books & Radio */
.min-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3em;
    grid-row-gap: 3em;
    padding: 2em 0;
    -webkit-user-select: none;
    user-select: none;
}

.lit-container {
    background-color: var(--white);
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    /* padding-bottom: 1.5em; */
    text-align: center;
}
.lit-container:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

.button-container {
    background-color: var(--white);
    text-align: center;
    border: 2px solid var(--darkgray);
    border-radius: 100px;
    transition: transform 0.5s;
    -webkit-user-select: none;
    user-select: none;
}
.button-container i {
    margin: 0;
}
/* End: Grid Books & Radio */

.music-player {
    margin: auto;
    max-width: 800px;
    height: 450px;
}

.text-post {
    letter-spacing: normal;
}

.music-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3em;
    grid-row-gap: 3em;
}
.topic-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}
.card-topic-link {
    border-radius: 1.5em;
    transition: transform 0.5s;
    -webkit-user-select: none;
    user-select: none;
    margin-inline: 1em;
}
.card-topic-link:hover i{
    color: var(--purple);
    box-shadow:none;
    transition: 0.5s;
    /*transform: translate(10px, 0px); */
}
.card-topic-link h4 {
    font-size: 15px;
    margin-block-start: 0;
}
.card-topic-link i {
    color: var(--cont);
    border:1.5px solid var(--darkgray);
    border-radius: 1em;
    transition: 0.5s;
}

@media (max-width: 480px) {
    .card-post {
        width: 100%;
    }
    .welcome, .min-container, .music-container {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 3em;
    }
    .min-container {
        margin: 2em;
    }
    .welcome-text, .welcome {
        margin: 1em;
    }
    .last-posts{
        padding-left: 1.5em;
        padding-right: 1.8em;
    }
    .topic-links {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 1em;
    }
}