@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Autour+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap');

/* Root */
/* From dark to light */

:root {
    --lightbeige: #F7F2EE;
    --beige: #CEAC92;
    --kbeige: #D4CBC0;
    --tbp-pink: #E3CBCB;
    --tbp-purple: #BFC3CC;
    --tbp-brown: #8a8685;
    --black: #000;
    --darkgray: #222222;
    --gray: #515151;
    --brown: #825C4F;
    --darkpink: #d1a9a9;
    --pink: #F9AFBE; /* e7c9c4: Antiguo pink; current pink: #f7caca */ 
    --realpink: #F9AFBE; /* #e88b9e  new color: F4BBC9*/
    --lightpink: #f4e7e5;
    --llpink: #FAF6F6;
    --selector: #d1a9a94d;
    --bgcolor: #faf6f6;
    --white: #fff;
    --cyan: #b18fb0; /*Cyan: #b0efef*/
    --purple: #b18fb0;
    --yellow: #feffbf;
    --cont: #f5f5f5;
}


::-webkit-scrollbar {
    width: 0.8em;
}

::selection {
    color: var(--gray);
    text-shadow: 0 0 10px var(--darkgray);
}
::-moz-selection {
    color: var(--gray);
    text-shadow: 0 0 10px var(--darkgray);
}
::-webkit-scrollbar-thumb {
    background-color: var(--pink);
    border-radius: 1em;
    border: 0;
}
::-webkit-scrollbar-track {
    background: var(--cont);
}

body {
    background-color: var(--bgcolor);
    scroll-behavior: smooth;
    margin: 0;
}

.container-1 {
    background-color: var(--cyan);
    min-height: 100vh;
    padding-top: 2em;
}

.container-2 {
    background-color: var(--cont);
    min-height: 100vh;
    padding-top: 2em;
}

.container-3 {
    background-color: var(--realpink); /*Se cambió pink -> realpink*/
    min-height: 100vh;
    padding-top: 2em;
}

.container-mix {
    margin: auto;
    max-width: 800px;
    padding: 2em;
}

.main-container {
    margin: auto;
    max-width: 800px;
    padding-top: 2em;
    padding-bottom: 2em;
}

.container {
    margin: auto;
    background-color: var(--white);
    max-width: 800px;
    margin-top: -5em;
    padding-top: 0.1em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 10px 10px 0 0;
    z-index: 100;
}

.sub-container {
    border: 2px solid var(--darkgray);
    padding-inline: 2em;
    border-radius: 1.5em;
    background-color: var(--cont);
}
.subcontainer {
    border: 0 solid var(--darkgray);
    margin-top: 5em;
}

h1 {
    color: var(--darkgray);
    text-align: center;
    /* font: 3rem "Playfair Display"; */
    font-family: 'Outfit', sans-serif;
    font-size: 2.5em;
    font-weight: 700;
    font-style: normal;
    text-transform:initial;
    line-height: 1.5;
}

h2 {
    color: var(--darkgray);
    font-family: 'Outfit', sans-serif;
    font-size: 2em;
    font-weight: 600;
    text-transform: initial;
    line-height: 2em;
}

h3 {
    color: var(--darkgray);
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    text-transform: initial;
    font-size: 1.5em;
}

h4 {
    color: var(--darkgray);
    font: 1.4em "Montserrat";
    /* font-family: 'Jost','Source Sans Pro',serif; */
    font-size: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
}

h5 {
    color: var(--darkgray);
    font: 1.2em "Montserrat";
    font-weight: 600;
    text-transform: uppercase;
}

p {
    color: var(--darkgray);
    /* font-family: 'Jost','Source Sans Pro',serif; */
    font-family: 'Outfit', sans-serif;
    font-weight: 200;
    text-align: justify;
    font-size: 1.2em;
    line-height: 2rem;
    display: block;
    margin-block-start: 1.5em;
    margin-block-end: 1.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    letter-spacing: 0.5px;
}

.box-title {
    margin: 5em 5em 2em 5em;
    background: var(--white);
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    -webkit-user-select: none;
    user-select: none;
}
.box-title h1 {
    margin-inline: 1em;
}

.box-title {
    word-break: break-word;
}

blockquote {
    margin: 1em auto;
    font-family: 'Jost','Source Sans Pro',serif;
    max-width: 30rem;
    padding-bottom: 1em;
    font-style: italic;
}
blockquote::before {
    content: "\201C";
    font-size: 2em;
}
blockquote::after {
    content: '';
}

.upper {
    text-transform: uppercase;
}
.center {
    text-align: center;
}

a {
    color: var(--black);
    font-family: 'Jost','Source Sans Pro',serif;
    /* font: 1.2em "Montserrat"; */
    font-weight: 400;
    text-decoration: none;
}

/* Listas */
ul, ol {
    font-weight: 200;
}

li {
    font-family: 'Outfit', sans-serif;
    font-size: 1.2em;
    line-height: 2em;
}
ol, ul {
    color: var(--darkgray);
}

mark {
    background: var(--pink);
}

hr {
    color: var(--darkgray);
}

/*Phone Devices*/
@media (max-width: 480px) {
    .main-container {
        padding-inline: 0.2em;
    }
    .container-1, .container-2, .container-3 {
        padding: 0;
    }
    .box-title {
        margin: 5em 1em 2em;
    }
    .sub-container {
        padding-inline: 1em;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.8em;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .main-container {
        padding-inline: 2em;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .main-container {
        padding-left: 2em;
        padding-right: 2em;
    }
}