.top {
    margin: auto;
    max-width: 100%;
    height: 0;
    position: relative;
    top: 4em;
    display: flex;
    align-items: center;
    z-index: 999;
    -webkit-user-select: none;
    user-select: none;
    justify-content: space-between;
}

.topLeft {
    flex: 3;
    display: flex;
    align-items: right;
    justify-content: right;
}

.topLeft img {
    /* padding-top: 2em;
    margin: auto; */
    max-width: 15em;
}

.container-logo {
    margin: auto;
    padding-top: 1em;
    max-width: 800px;
}

.textLogo {
    text-align: right;
}

.logo-ws {
    text-align: center;
}
.logo-ws img {
    width: 40%;
}

.topRight {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topIcon {
    margin-right: 10px;
    cursor: pointer;
    color: var(--darkgray);
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem {
    margin-right: 50px;
    position: relative;
    cursor: pointer;
    font: "Montserrat";
    text-transform: uppercase;
    color: var(--darkgray);
    z-index: 3;
}
.topListItem a {
    color: var(--darkgray);
    font-weight: 600;
    font-size: 1em;
    text-align: center;
    letter-spacing: 0.1em;
}
.topListItem:hover a {
    background-color: var(--purple);
    transition: 0.5s;
    border: 2px solid var(--darkgray);
    border-radius: 1.5em;
    padding-inline: 0.5em;
}
.topListItem:after {
    content: "";
    position: absolute;
    background-color: var(--darkpink);
    /* height: 3px;
    width: 0;
    left: 0.5em;
    bottom: -5px; */
}
.topListItem:hover::after {
    width: 100%;
}

.ham {
    display: none;
}

@media (max-width: 480px) {
    .tbp-logo {
        width: 100%;
    }
    .top {
        top: 0;
    }
    .topLeft {
        flex: 4;
        margin-top: 2em;
        text-align: center;
    }
    .topLeft, .topRight {
        padding-top: 5em;
    }
    .topLeft img {
        padding-top: 0;
    }
    .ham {
        display:block;
        z-index: 100;
        /* padding-top: 2rem; */
    }
    .topIcon {
        font-size: 1.5rem;
    }
    .topRight{
        flex: 1;
        flex-direction: column;
        vertical-align: middle;
        justify-content: right;
        text-align: right;
    }
    .topList{
        position: fixed;
        
        top: 0;
        left: -100%;
        flex-direction: column;
        text-align: center;
        transition: 0.2s;
        width: 100%;
        height: 100vh;
        z-index: 1;
        
    }
    .topListItem{
        margin-right: 0;
    }
    .topListItem:hover a {
        background-color: var(--pink);
        /* color: var(--white); */
        /* text-decoration: line-through 5px solid var(--cont); */
        border: none;
        border-radius: 1.5em;
        padding-inline: 0.5em;
    }
    .topList.active{
        background-color: var(--pink);
        position: fixed;
        background-attachment: fixed;
        transform: translateY(0);
        transition: 0.5s;
        left: 0;
    }
    .topList li{
        padding: 1rem 0;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .tbp-logo {
        width: 100%;
    }
    .topListItem{
        margin-right: 1.5rem;
    }
    .textLogo {
        text-align: center;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .tbp-logo {
        width: 100%;
    }
    .topListItem{
        margin-right: 1.5rem;
    }
    .textLogo {
        text-align: left;
    }
}