.signature p {
    text-align: center;
    font: 2em "Sacramento";
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.container-signature {
    background-color: var(--cont);
    border: 2px solid var(--darkgray);
    border-radius: 1.5em;
    -webkit-user-select: none;
    user-select: none;
    margin-top: 5em;
    text-align: center;
}
.container-signature img {
    width: 50%;
}

@media (max-width: 480px) {
    .container-signature img {
        width: 100%;
    }
}