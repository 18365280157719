.container-kit {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2em;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
}
.pack img {
    width: 100%;
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
}

.pack a {
    margin-top: 1em;
}

/* Start: Classnotes */
.container-classnotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.classnotes-item {
    margin: 1em;
    padding: 0.5em 1em;
    background: var(--cont);
    border: 2px solid var(--darkgray);
    border-radius: 1em;
    text-align: center;
    font-size: 1.5em;
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
}
.classnotes-item i {
    margin: 0;
    font-size: 2em;
}
.classnotes-item:hover {
    color: var(--cont);
    transition: 0.5s;
    background: var(--purple);
    box-shadow:none;
    transform: translate(10px, 10px);
}
/* End:   Classnotes */

/*Phone Devices*/
@media (max-width: 480px) {
    .container-kit {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 2em;
        padding-left: 1em;
        padding-right: 1.8em;
    }
}