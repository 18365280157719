.canciones-grid {
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3em;
    grid-row-gap: 3em;
}

.song-card img {
    width: 100%;
    border: 2px solid var(--darkgray);
}

@media (max-width: 480px) {
    .canciones-grid {
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
    }
}