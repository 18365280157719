.foot-bar {
    /* background-color: var(--white); */
    margin: auto;
    max-width: 1000px;
    -webkit-user-select: none;
    user-select: none;
}

.foot-links {
    min-height: 2em;
    max-height: 10em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    background: var(--realpink);
}
.foot-links a {
    margin: auto;
}
.foot-link-item {
    width: 6em;
    text-align: center;
    transition: 0.5s;
}
.foot-link-item:hover {
    transition: 0.5s;
    font-size: 1em;
    border-radius: 1em;
    border: 2px solid var(--darkgray);
    color: var(--cont);
    background: var(--purple);
}

.bottom-container {
    -webkit-user-select: none;
    user-select: none;
    /* background-color: var(--white); */
}

.instafeed-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    padding: 3em 2em 1em 2em;
}

.instafeed-container img {
    width: 90%;
    border: 5px solid var(--darkgray);
    border-radius: 1em;
}
.instafeed-container img:nth-child(odd) {
    transform: rotate(-10deg);
    transition: 0.8s;
}
.instafeed-container img:nth-child(odd):hover {
    transform: rotate(10deg);
}
.instafeed-container img:nth-child(even) {
    transform: rotate(10deg);
    transition: 0.8s;
}
.instafeed-container img:nth-child(even):hover {
    transform: rotate(-10deg);
}

.bottom {
    /* height: 14.75rem; */
    height: 3rem;
    /* background-color: var(--white); */
    text-align: center;
}

.copyr p{
    color: var(--black);
    text-align: center;
    font-size: 0.8em;
    margin: 0;
    padding: 0.7em;
}

/*Phone Devices*/
@media (max-width: 480px) {
    .instafeed-container {
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 1em;
    }
}
@media (min-width: 481px) and (max-width: 768px) {
    /*  */
}