/* Styling */
.title-month {
    margin: auto;
    width: 15em;
    cursor: pointer;
}
.title-month > h3 {
    text-align: center;
    color: var(--cont);
    border-radius: 1em;
    padding: 0.2em;
    background-color: var(--purple);
    border: 2px solid var(--darkgray);
}
/* Books Flex */
.month-card {
    margin-bottom: 5em;
    -webkit-user-select: none;
    user-select: none;
}
.pix-books {
    background: none;
    display: flex;
    flex-wrap: wrap;
    gap: 3.5em;
    justify-content: center;
    margin-bottom: 2em;
}


.book-card {
    width: 14em;
    height: 24em;
    background-color: var(--white);
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    text-align: center;
    cursor: pointer;
}
.book-card:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}

.book-card img{
    width: 11.5em;    /*11*/
    height: 18em;
    object-fit: cover;
}

.book-card p {
    font-size: 15px;
    text-align: center;
    text-transform: capitalize;
    margin: 0em;
    line-height: 1em;
}

.book-pic {
    margin-top: 1.3em;
    border: 2px solid var(--darkgray);
}

.book-title {
    padding-top: 5px;
    line-height: 1em !important;
}
.book-author {
    padding-top: 5px;
    padding-inline: 0.1em;
    line-height: 1em !important;
}



/*Phone Devices*/
@media (max-width: 480px) {
    .pix-books {
        gap: 3em 1em;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .pix-books {
        gap: 3em 2em;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .pix-books {
        gap: 3em;
    }
}