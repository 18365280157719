/* About-Me Container */
.about-me, .about-radio{
    margin-top: 5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

.pic-about {
    text-align: center;
    padding-top: 1rem;
    -webkit-user-select: none;
    user-select: none;
}
.pic-about img {
    width: 60%;
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    transform: rotate(-10deg);
}
.pic-about img:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}
.about-text {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
}

.about-text, .upper, .sub-t {
    text-align: center;
}

/* Social Networks */
.social-network {
    display: flex;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    margin: 1em;
}
.social-icon {
    transition: 0.5s;
    font-size: 2rem;
    color: var(--darkpink);
}
.social-icon:hover {
    color: var(--lightpink);
}

/*Start: Banner */
.banner {
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
}
.banner img {
    width: 100%;
}
/* End: Banner */

/* Start: About Radio */
.about-radio {
    grid-column-gap: 3em;
    -webkit-user-select: none;
    user-select: none;
}
.about-radio p {
    text-align: center;
}
.radio-text {
    text-align: center;
}
.radio-pic {
    position: relative;
    width: 100%;
}
.radio-pic img {
    width: 100%;
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
}
.radio-pic img:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}
.pic1 {
    position: absolute;
    right: 2em;
    top: 0;
    width: 50%;
}
.pic2 {
    padding-top: 5em;
    width: 50%;
}
.pic4 {
    position: absolute;
    top: 0;
    width: 50%;
}
.pic3 {
    right: 2em;
    padding-top: 5em;
    width: 50%;
    position: absolute;
}
.pic3:hover {
    z-index: 10;
}
/* End:About Radio */

/* Start: About Music */
.about-music {
    padding-bottom: 5em;
}
.music-pic {
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    padding: 1em 10em;
}
.music-pic img {
    border: 2px solid var(--darkgray);
    box-shadow: 10px 10px 1px var(--darkgray);
    border-radius: 1.5em;
    width: 80%;
}
.music-pic img:hover {
    box-shadow:none;
    transition: 0.5s;
    transform: translate(10px, 10px);
}
/* End: About Music */

/* Devices */
@media (max-width: 480px) {
    .about-me {
        grid-template-columns: 1fr;
    }
    .banner img {
        width: 100%;
    }
    .about-radio{
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 1em;
        text-align: center;
        padding-bottom: 20em;
    }
    .radio-pic{
        grid-template-columns: 2fr;
        text-align: center;
    }
    .music-pic {
        padding: 0;
    }
}