/*Pic grande*/
.picinpost, .picxinpost, .picsminpost{
    text-align: center;
}
.picinpost img {
    width: 80%;
}
.picxinpost img {
    height: 10em;
}

.pic-post-square {
    max-width: 350px;
    margin: auto;
}

.pic-post-square img {
    width: 100%;
}

.pic-post img {
    width: 100%;
}

.codeblock {
    margin: auto;
    max-width: 30rem;
}
.code-pix {
    text-align: center;
}

.code-pix img {
    border-radius: 1em;
}

table {
    margin: auto;
    width: 80%;
    text-align: center;
    border-collapse: collapse;
    line-height: 2rem;
    font: 1rem "Laila";
}
th, td {
    width: 25%;
    border: 1px solid var(--darkpink);
}

/* Tag and Date */
.label-date {
    display: flex;
    justify-content:space-evenly;
    -webkit-user-select: none;
    user-select: none;
}
.boxlabel {
    font: 13px 'Montserrat';
    text-transform: uppercase;
}
.boxlabel:hover {
    color: var(--pink);
}
/*  End: Tag and Date */

/* Start: Iframe */
.snappie {
    overflow:hidden;
    margin-left:auto;
    margin-right:auto;
    border-radius:10px;
    width:100%;
    position:relative;
    text-align: center;
}

.snap {
    left:0;
    top:0;
    width:100%;
}
/* End: Iframe */

/* Start: Text Links */
.textlinks {
    color: var(--darkpink);
}
.textlinks:hover {
    color: var(--darkgray);
    text-decoration: line-through;
    text-decoration-color: var(--darkpink);
}
/* End: Text Link */

/* Start: Book WU */
.book-wu {
    text-align: center;
}
.book-wu img {
    width: 250px;
    border-radius: 1em;
    border: 5px solid var(--purple);
}
/* End: Book WU */

/* Devices */
@media (max-width: 480px) {
    .picinpost img {
        width: 100%;
    }
    .picsminpost img{
        width: 100%;
    }
}